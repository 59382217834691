import axios from "axios"
import { API_ENDPOINT, API_KEY, AUTH_URI } from "@/config"

const authApi = axios.create({
  baseURL: `${API_ENDPOINT}/${AUTH_URI}`,
  timeout: 0,
})
export class AuthService {
  refreshToken() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${API_KEY}`
    }
    return authApi.post('', {}, { headers })
  }
}

const authService = new AuthService()

export default authService
