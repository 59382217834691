import * as dayjs from "dayjs";

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const sessionStorage = () => {
    let config = {
        expireKey: "_expiresAt",
        versionKey: "_version",
        cacheVersion: null,
      }
    return {
        get(key, defaultVal = null) {
            const item = window.sessionStorage.getItem(key);
            if (!item) return defaultVal;
            return JSON.parse(window.sessionStorage.getItem(key));
          },

        set(key, val, metaData = {}) {
            const { expiresAt = null, version = config.cacheVersion } = metaData;
            expiresAt && this.setExpireAt(key, expiresAt);
            this.setVersion(key, version);
            window.sessionStorage.setItem(key, JSON.stringify(val));
        },

        setExpireAt(key, date) {
            const storeDate = dayjs(date).isValid() ? date : dayjs(date);
            window.sessionStorage.setItem(`${key}${config.expireKey}`, storeDate);
        },

        // check if an expire date is set for this key
        hasExpireDate(key) {
          const expiresAt = window.localStorage.getItem(`${key}${config.expireKey}`);
          return Boolean(expiresAt);
        },

        setVersion(key, version) {
            window.sessionStorage.setItem(`${key}${config.versionKey}`, version);
        },
      
        delete(key) {
            window.sessionStorage.removeItem(key);
            window.sessionStorage.removeItem(`${key}${config.expireKey}`);
            window.sessionStorage.removeItem(`${key}${config.versionKey}`);
        },
    }
}


export default {
    install(Vue, options) {
        const sessionStorageEngine = sessionStorage();
        sessionStorageEngine.setConfig(options);
        Vue.prototype.$sessionStorage = sessionStorageEngine;
    }
}