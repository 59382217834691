import { GET_UX_JOURNEY_CONFIG } from "./actions";
import { GET_UX_JOURNEY_CONFIG_SUCCESS } from "./mutations";
import customerDetailService from "@/services/customer-detail";

// Alway define initial arrays as separate variables and map to store only the first time call action to update the store
// It will prevent array duplicate issue in Vuex
const initialWhatToExpect = [
  "Before your car is officially listed for sale, a member of our customer service team will contact you within the next business day.",
  "They'll confirm a few details, advise you on valuing your car, answer any questions and then go to work to get you the best price, fast.",
  "In the meantime, you can continue updating your details by clicking the link provided in the confirmation email you'll receive shortly."
];

const initialErrorsMailboxes = [
  "LPErrors@idomi.com.au",
  "dmv.01.test@gmail.com"
]

const initialState = {
  sharedMailboxes: {
    reportIssues: "testleadupdates@idomi.com.au",
    unsubscribeEmail: "privacy@autoflip.com.au",
    errorsMailbox: []
  },
  socialLinks: {
    socialMediaIcons: true,
    facebook: "https://www.facebook.com/autoflipaustralia",
    instagram: "https://www.instagram.com/autoflipau",
    twitter: "https://www.twitter.com/AutoflipAU",
    linkedIn: "https://www.linkedin.com/company/autoflip-australia",
    youtube: "https://www.youtube.com/@autoflip6451"
  },
  contactDetails: {
    email: "info@autoflip.com.au",
    phoneNumber: "1300 816 821",
    mailIcon: "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_mail.svg",
    phoneIcon: "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_telphone.svg"
  },
  policyDocumentsLinks: {
    sellerAgreement: "https://www.autoflip.com.au/seller-agreement",
    sellerFee: "https://www.autoflip.com.au/seller-fees",
    termsAndConditions: "https://www.autoflip.com.au/terms-and-conditions",
    privacyPolicy: "https://www.autoflip.com.au/privacy-policy"
  },
  photoGuideLink: {
    fromLandingPage: "https://www.autoflip.com.au/photo-guide",
    fromMyCar: "https://www.autoflip.com.au/photo-guide"
  },
  myCarLinks: {
    buildDate: "https://www.autoflip.com.au/find-build-date",
    troubleLogging: "https://www.autoflip.com.au/login-issues",
    guidedCamera: "https://www.autoflip.com.au/guided-camera"
  },
  footerBooleans: {
    googleReviewRating: true,
    howItWorks: true,
    forDealers: true,
    forEnterprise: true,
    aboutUs: true,
    contact: true,
    faq: true,
    howWeWork: true,
    ourLocations: true,
  },
  footer: {
    footerTexts: "AutoFlip connects private sellers of cars with our 600+ trusted wholesale buying network, Australia-wide.",
    googleReviewBadge: "https://embedsocial.com/api/source_image_badges/svg-badge-1-g/en/google/52337",
    googleReview: "https://www.google.com/search?q=autoflip&rlz=1C1GCEA_enAU1009AU1010&oq=auto&aqs=chrome.1.69i60j69i59l2j69i57j69i60l2j69i65j69i60.2288j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6ad643c9cf26ce91:0x52eb8c8ec5ff06e6,1,,,",
    leaveReviewLink: "https://search.google.com/local/writereview?placeid=ChIJkc4mz8lD1moR5gb_xY6M61I",
    howItWorks: "https://www.autoflip.com.au/how-it-works",
    forDealers: "https://www.autoflip.com.au/dealers",
    forEnterprise: "https://www.autoflip.com.au/enterprise",
    aboutUs: "https://www.autoflip.com.au/about",
    contact: "https://www.autoflip.com.au/contact",
    faq: "https://www.autoflip.com.au/faqs",
  },
  homepageLink: "https://www.autoflip.com.au",
  logo: {
    logoHeaderAndFooter: "https://psp-test.idomitech.net/inv02/img/logo_header.81932755.svg",
    logoHeaderTopPage: "https://idom-images.s3.ap-southeast-2.amazonaws.com/logo_header.svg",
    logoLink: "https://www.autoflip.com.au",
  },
  businessNumber: "ABN: 87 638 825 751",
  emailReviewBagde: "https://api.psp-test.idomitech.net/view-assets/google_review.png",
  autoflipLogo: "https://www.autoflip.com.au",
  copyBlocks: {
    valuationDisclaimer: "* The price guide is created using independent market data. It is indicative only and relies on the vehicle information provided at the time of submission. Details such as vehicle specification, number of kilometres, and condition of vehicle all impact the price guide. The final sale price may differ and will depend on market conditions and demand from our dealer network. AutoFlip is not responsible for any errors provided or omissions of information.",
    marketingCheckbox: "Receive occasional promotional communications from AutoFlip. We will not spam you and you can opt out at any time.",
    feesAgreementsCheckbox: {
      text: "I agree to and acknowledge AutoFlip's seller {0} and {1} - NO DEAL, NO FEE",
      links: [
        {
          name: "agreement",
          link: "https://www.autoflip.com.au/seller-agreement",
        },
        {
          name: "success fee",
          link: "https://www.autoflip.com.au/seller-fees",
        }
      ]
    },
    thanksPageHeader: "Thank you test {customerName} for your enquiry on AutoFlip.",
    whatToExpect: [],
    photoTipsBanner: {
      photoText: "The more photos you add, the easier it is to sell your car. For best results, we recommend adding at least 15 photos of your car.",
      photoTextLink: "Tips for taking great photos",
      photoLink: "https://ws-staging.idomitech.net/photo-guide",
    },
    quickTipsBanner: {
      tipsHeader: "QUICK TIP",
      tipsContent: "Don't forget to add photos of your odometer, tyres and any damages."
    },
    emailGhostText: "Enter your email"
  },
  theme: {
    primaryColor: "#1F8252",
    subHeaderText: "#00bb65",
    photoUploadIcons: "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_photo_upload.svg",
    starRating: "#1F8252",
    coverPhotoBorder: "#05b36d",
    photoTipsBanner: {
      text: "#3AAC5D",
      background: "#E1F6ED",
      border: "#0AB76D",
    },
    quickTipsBanner: {
      text: "#463209",
      background: "#FFF6ED",
      border: "#F5CE84",
    },
    addMorePhotosBanner: {
      text: "#333",
      background: "#82d6a3",
      border: "#1f8252",
    },
    carConditionsThanksPage: {
      ownersManual:
        "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_logbook.svg",
      serviceHistory:
        "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_history.svg",
      keys:
        "https://idom-images.s3.ap-southeast-2.amazonaws.com/ic_service.svg",
    },
    secondaryColor: "#1f825280",
  },
  brandName: "AutoFlip",
  heroSection: {
    image: "https://idom-images.s3.ap-southeast-2.amazonaws.com/hero_image.png",
    content: {
      main: "Sell your car,",
      highlight: " fast for the best price"
    },
    subContent: "More than 1000 licensed dealers across Australia, competitively bid to buy your car."
  }
};

const state = { 
  ...initialState,
};

const actions = {
  [GET_UX_JOURNEY_CONFIG](context, uxeId) {
    return new Promise((resolve, reject) => {
      customerDetailService.getUXJourneyConfig({ uxeId })
        .then(({ data }) => {
          context.commit(GET_UX_JOURNEY_CONFIG_SUCCESS, data.variablesConfig);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

const mutations = {
  [GET_UX_JOURNEY_CONFIG_SUCCESS](state, payload) {
    state.sharedMailboxes = {
      ...payload.sharedMailboxes,
      errorsMailbox:
        payload.sharedMailboxes?.errorsMailbox || initialErrorsMailboxes,
    };
    state.socialLinks = payload.socialLinks;
    state.contactDetails = payload.contactDetails;
    state.policyDocumentsLinks = payload.policyDocumentsLinks;
    state.photoGuideLink = payload.photoGuideLink;
    state.myCarLinks = payload.myCarLinks;
    state.footerBooleans = payload.footerBooleans;
    state.footer = payload.footer;
    state.homepageLink = payload.homepageLink;
    state.logo = payload.logo;
    state.businessNumber = payload.businessNumber;
    state.emailReviewBagde = payload.emailReviewBagde;
    state.autoflipLogo = payload.autoflipLogo;
    state.copyBlocks = {
      ...payload.copyBlocks,
      whatToExpect: payload.copyBlocks?.whatToExpect || initialWhatToExpect,
    };
    state.theme = payload.theme;
    state.browserTab = payload.browserTab;
    state.brandName = payload.brandName;
    state.heroSection = payload.heroSection;
  }
};

export default {
  state,
  actions,
  mutations,
};
