import webApi from "./api"

export class TopPageService {
    searchRego(data) {
        return webApi.post('car-detail/rego-redbook-api', data)
    }
}

const topPageService = new TopPageService()

export default topPageService
