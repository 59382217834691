export const STEP = 'step'
export const VEHICLE_INFO = 'vehicle_info'
export const PHOTO_UPLOAD = 'photo-upload'
export const MAX_FILE_SIZE = 20 * 1024 * 1024;
export const MAX_UPLOAD_FILE_SIZE = 1 * 1024 * 1024;
export const EXTENSIONS = ['jpg', 'jpeg', 'png', "tif", "tiff", "heic"];
export const MAIN_PAGE_URL = "https://sell.psp-frontend.irp-test.site";
export const AUTOFLIP_URL = "https://www.autoflip.com.au";

export const KMs = [
  '0 ~ 10,000 kms',
  '10,001 ~ 20,000 kms',
  '20,001 ~ 30,000 kms',
  '30,001 ~ 40,000 kms',
  '40,001 ~ 50,000 kms',
  '50,001 ~ 60,000 kms',
  '60,001 ~ 70,000 kms',
  '70,001 ~ 80,000 kms',
  '80,001 ~ 90,000 kms',
  '90,001 ~ 100,000 kms',
  '100,001 ~ 110,000 kms',
  '110,001 ~ 120,000 kms',
  '120,001 ~ 130,000 kms',
  '130,001 ~ 140,000 kms',
  '140,001 ~ 150,000 kms',
];
export const yearRange = () => {
  const curYear = new Date().getFullYear();
  const endYear = 1990;
  const years = [];
  for (var i = curYear; i >= endYear; i--) {
    years.push(i);
  }
  return years;
}

export const modelYearRange = () => {
  const curYear = new Date().getFullYear();
  const endYear = 1956;
  const model_years = [];
  for (var i = curYear; i >= endYear; i--) {
    model_years.push('MY' + i.toString().substr(-2));
  }
  return model_years;
}

export const socialNetworkBrowser = ['FBAN', 'FBAV', 'Instagram'];

export const browserList = ['CriOS'];

export const blankOptionList = ['Not sure', 'Other'];

export const SENTRY_LEVEL = {
  FATAL: 'fatal',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  DEBUG: 'debug',
};