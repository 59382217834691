import { apiEndpointTransform } from '../helper/utils';

export const BASE_API_ENDPOINT = apiEndpointTransform(window.location.hostname)
export const API_ENDPOINT = `${BASE_API_ENDPOINT}/api/v1`
export const VUE_APP_BASE = process.env.VUE_APP_BASE
export const API_KEY = process.env.VUE_APP_API_KEY
export const AUTH_URI = 'auth'
export const LP_TYPE = process.env.VUE_APP_LP_TYPE

// Decrypt
export const ENC_PASSWORD = process.env.VUE_APP_ENC_PASSWORD
export const ALGORITHM = 'aes-256-cbc'