import { routers } from '@/helper/routers';

export default[
    {
        path: routers.REGO_VEHICLE_INFO,
        name: 'vehicle-info',
        component: () => import('@/views/vehicle-info/Index'),
        meta: {
            step: 1,
        }
    },
]