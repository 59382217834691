import { ACTION_REGO_SEARCH, ACTION_REGO_REMOVE, ACTION_REGO_SEARCHED, ACTION_RESET_REGO_SEARCHED } from './actions'
import { REGO_SEARCH, REGO_REMOVE, REGO_SEARCHED, RESET_REGO_SEARCHED } from './mutations'
import { SET_INFO_SUCCESS } from '../info/mutations'
const state = {
    rego: {},
    searched: false,
}

const getters = {
    regoSearch(state) {
        return state.rego
    },
    searched(state) {
        return state.searched
    }
}

const actions = {
    async [ACTION_REGO_SEARCH](context, payload) {
        context.commit(REGO_SEARCH, payload)
        context.commit(SET_INFO_SUCCESS, {
            ...payload
        })
    },

    async [ACTION_REGO_REMOVE](context) {
        context.commit(REGO_REMOVE)
    },

    async [ACTION_REGO_SEARCHED](context) {
        context.commit(REGO_SEARCHED)
    },

    async [ACTION_RESET_REGO_SEARCHED](context) {
        context.commit(RESET_REGO_SEARCHED)
    }
}

const mutations = {
    [REGO_SEARCH](state, payload) {
        state.rego = payload
    },

    [REGO_REMOVE](state) {
        state.rego = {}
    },

    [REGO_SEARCHED](state) {
        state.searched = true
    },

    [RESET_REGO_SEARCHED](state) {
        state.searched = false
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}
