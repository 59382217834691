import axios from "axios"
import { API_ENDPOINT, AUTH_URI } from "../config"
import store from '@/store';
import Vue from "vue"

const webApi = axios.create({
    baseURL: API_ENDPOINT,
    timeout: 0,
})
webApi.interceptors.request.use(
    async config => {
        if (!store.getters.token)
            await store.dispatch('fetchToken');
        const token = store.getters.token;
        if (config.url !== AUTH_URI) {
            config.headers = {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

webApi.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        if (error.config && error.response && error.response.status === 401) {
            await store.dispatch('fetchToken');
            const token = store.getters.token;
            // successfully refresh token
            if (token) {
                const originalRequest = error.config;
                originalRequest.headers = {
                    Authorization: `Bearer ${token}`
                }
                const formDataOrUndefined = originalRequest.data instanceof FormData || !originalRequest.data;
                originalRequest.data = !formDataOrUndefined
                    ? JSON.parse(originalRequest.data)
                    : originalRequest.data;
                window.localStorage.setItem('token', token)
                return webApi(originalRequest);
            }
        }
        if (!error.response) {
            const message = error.config && error.config.url === '/car-detail/image' ? 'Network issue: One or more photos were NOT added. Please try uploading it again.' : 'Failed to submit information. Please wait for a while and try again.'
            Vue.$toast.clear()
            Vue.$toast.error(message)
        }
        return Promise.reject(error)
    }
)

export default webApi
