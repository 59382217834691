const state = {
  uploadImage: [],
  sendPhotoLater: true,
  takePhotoWithGuidedCamera: false,
}

const getters = {
  uploadImage (state) {
    return state.uploadImage
  },
  sendPhotoLater (state) {
    return state.sendPhotoLater
  },
  takePhotoWithGuidedCamera (state) {
    return state.takePhotoWithGuidedCamera
  },
}
const actions = {
  setUploadedImages (context, data) {
    context.commit('setUploadedImages', data)
  },
  clearImages (context) {
    context.commit('clearImages')
  },
  setSendPhotoLater (context, data) {
    context.commit('setSendPhotoLater', data)
  },
  clearSendPhotoLater (context) {
    context.commit('clearSendPhotoLater')
  },
  setTakePhotoWithGuidedCamera (context, data) {
    context.commit('setTakePhotoWithGuidedCamera', data);
  },
}

const mutations = {
  setUploadedImages (state, payload) {
    state.uploadImage = payload
  },
  clearImages (state) {
    state.uploadImage = []
  },
  setSendPhotoLater (state, payload) {
    state.sendPhotoLater = payload
  },
  clearSendPhotoLater (state) {
    state.sendPhotoLater = true
  },
  setTakePhotoWithGuidedCamera (state, data) {
    state.takePhotoWithGuidedCamera = data
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}