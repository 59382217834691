import webApi from './api'

export class VehicleInfoService {
  async getInfo (params) {
    return webApi.get('/car-detail/redbook-october', {
      params
    });
  }
  getInfoByRego (regoNumber, state) {
    return webApi.post('/car-detail/rego-search', { regoNumber, state });
  }
  getValuationConfig (params) {
    return webApi.get('/valuation-config/get-valuation-config', {
      params
    });
  }
}

const vehicleInfoService = new VehicleInfoService()

export default vehicleInfoService
