import { sessionStorage } from "@/plugins/session-storage";
import * as dayjs from "dayjs";

const sessionStorageEngine = sessionStorage();

export const CACHE_MODULE_NAME = "LOCAL_CACHE_MODULE";
export const CLEAR_STORE_CACHE = "LOCAL_CLEAR_STORE_CACHE";
export const UPDATE_CACHE_STATUS = 'UPDATE_CACHE_STATUS';
export const CACHE_STATUS = Symbol();
export const CacheStatus = {
  Empty: 'empty',
  New: 'new',
  Rehydrated: 'rehydrated'
};

const mergeDeep = (...objects) => {
  const isObject = (obj) => obj !== null && typeof obj === 'object';
  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      }
      else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      }
      else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

export const sessionStoragePersistence = ({ reducer = null, mutationFilter = null, cacheKey = 'store' }) => {
  return store => {
    store.registerModule(CACHE_MODULE_NAME, {
      state: {
        [CACHE_STATUS]: CacheStatus.Empty
      },
      getters: {
        [CACHE_STATUS]: (state) => state[CACHE_STATUS],
      },
      mutations: {
        [CLEAR_STORE_CACHE]: () => { },
      },
    })
    const cacheStore = sessionStorageEngine.get(cacheKey);
    if (cacheStore) {
      store.replaceState(mergeDeep(store.state, cacheStore));
      store.state[CACHE_MODULE_NAME][CACHE_STATUS] = CacheStatus.New;
    }
    store.subscribe((mutation, state) => {
      if (mutation.type === CLEAR_STORE_CACHE) {
        sessionStorageEngine.delete(cacheKey);
        return;
      }
      if (mutationFilter && !mutationFilter(mutationFilter)) return;
      const stateToSave = reducer ? reducer(state) : state;
      sessionStorageEngine.set(
        cacheKey,
        stateToSave,
        { expiresAt: !sessionStorageEngine.hasExpireDate() ? dayjs().add(1, 'day') : null }
      );
      sessionStorageEngine.set(
        'storageInfo',
        store.state[CACHE_MODULE_NAME][CACHE_STATUS],
      );
    })
  }
}