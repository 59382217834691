import AuthService from '@/services/auth'

const state = {
  step: 0,
  steps: {
    1: {
      step: 1,
      label: 'CAR INFO',
      name: 'vehicle-info',
      active: false,
      progress: 0,
    },
    2: {
      step: 2,
      label: 'PHOTOS',
      name: 'photo-upload',
      active: false,
      progress: 0,
    },
    3: {
      step: 3,
      label: 'CONTACT',
      active: false,
      name: 'customer-detail',
      progress: 0,
    }
  },
  token: null,
  fetchTokenPromise: null,
  queryString: null,
  progressStep: 1,
  lastProgressStep: 0,
}

const getters = {
  currentStep(state) {
    return state.steps[state.step];
  },
  nextStep(state) {
    return state.steps[state.step + 1];
  },
  steps(state) {
    return Object.values(state.steps);
  },
  step(state) {
    return state.step
  },
  token(state) {
    return state.token
  },
  queryString(state) {
    return state.queryString
  },
}
const actions = {
  setStep(context, step) {
    context.commit('setStep', step)
  },
  resetStep(context) {
    context.commit('resetStep')
  },
  resetProgressStep(context) {
    context.commit('resetProgressStep')
  },
  setProgress(context, add) {
    context.commit('setProgress', add)
  },
  setToken(context, token) {
    context.commit('setToken', token)
  },
  setQueryString(context, queryString) {
    context.commit('setQueryString', queryString)
  },
  fetchToken({ commit, state }) {
    if (!state.fetchTokenPromise) {
      commit('setToken', null);
      const tokenPromise = AuthService.refreshToken()
        .then(({ data }) => {
          commit('setToken', data.token);
          commit('setFetchTokenPromise', null);
        })
        .catch(() => {
          commit('setFetchTokenPromise', null);
        });
      commit('setFetchTokenPromise', tokenPromise)
    }
    return state.fetchTokenPromise;
  },
  watchStep(context, state ) {
    context.commit('watchStep', state)
  },
}

const mutations = {
  setStep(state, payload) {
    state.step = payload
    Object.values(state.steps).forEach(step => {
      state.steps[step.step] = step.step > payload ? {
        ...state.steps[step.step],
        active: false
      } : {
        ...state.steps[step.step],
        active: true
      };
    })
  },
  resetStep(state) {
    state.step = 0
    Object.values(state.steps).forEach(step => {
      state.steps[step.step] = {
        ...step,
        active: false,
        progress: 0
      }
    });
  },
  resetProgressStep(state) {
    state.progressStep = 1;
    state.lastProgressStep = 0;
  },
  setProgress(state, progress) {
    state.steps[state.step + 1] = {
      ...state.steps[state.step + 1],
      progress
    };
  },
  setToken(state, token) {
    state.token = token
  },
  setFetchTokenPromise(state, fetchingPromise) {
    state.fetchTokenPromise = fetchingPromise;
  },
  setQueryString(state, queryString) {
    state.queryString = queryString;
  },
  watchStep(state) {

    this._watcher = this.watch(
      () => state.step,
      (curVal, prevVal) => {
        if (curVal < prevVal && prevVal >= state.lastProgressStep) {
          state.lastProgressStep = prevVal;
          state.progressStep = prevVal;
        } else if (curVal > prevVal && prevVal >= state.lastProgressStep) {
          state.progressStep = curVal;
        }
      }
    );
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}